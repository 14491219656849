/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import bell from '../../assets/img/bell.gif';
import email from '../../assets/img/email.gif';
import { useAppSelector } from "../../redux/hook"


const urlMapping: any = {
  "fb.arachnomesh.com": "faraday-admin.arachnomesh.com",
  "fb-demo.arachnomesh.com": "fb-admin.arachnomesh.com",
  "fabricationbazar.com": "admin.bengaws.com",
  "localhost:3001": "localhost:3000"
}

function NotifyToast() {

  const productEnqId = useAppSelector((state: any) => {
    return state.enquiry?.enquiryId
  });
  const custName = useAppSelector((state: any) => {
    return state.customerLogin?.customer?.response?.customer?.user?.name
  });

  // const enqId = productEnq?.response?.enquiry?.id
  const userSession = sessionStorage.getItem("session") || localStorage.getItem("session")

  let timeoutId: any;
  useEffect(() => {
    timeoutId = setTimeout(function () {
      expand(false)
    }, 8000);
  }, []);

  const expand = (isClick: boolean) => {
    if (isClick) {
      clearTimeout(timeoutId)
    }
    let element = document.getElementById('toastId');
    element?.classList.add('expandToast');
  }


  // const protocol = useRef("https")
  const [protocol, setProtocol] = useState("https")
  useEffect(() => {

    if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
      // protocol.current = "http"

      setProtocol("http")
    }
    // eslint-disable-next-line
  }, [protocol])



  return (
    <>
      <div className="notifyToast " id="toastId">

        <div className="divfirst">
          <img src={bell} className='bell' alt='img' />

          <div className="Toasttxt">
            <h4>{`Hii, ${custName ? custName : "Customer"}`}</h4>
            <p>you have received one new notification</p>
            {/* <a  href={`https://faraday-admin.arachnomesh.com/auth/enquiryadd?tab=files&enquiryId=${enqId}`} target='_blank'> <Button variant="primary" className="btn solidBtn">View Details</Button></a> */}
            <Button onClick={() => expand(true)} variant="primary" className="btn solidBtn">View Details</Button>
          </div>
        </div>
        <div className="divsecond">
          <div className="Toasttxt">
            <img src={email} className='email' alt='img' />
            <h4> Enquiry sent successfully </h4>
            <p>Your enquiry with enquiry id {productEnqId} is sent successfully to the Fabrication Bazar team, our team will review your enquiry and update you its status.
              Thanks</p>
            <a href={`${protocol}://${urlMapping[window.location.host]}/login?source=${window.location.host}&sid=${userSession}&enquiryId=${productEnqId}`} target="_blank" rel="noreferrer" className="nav-link"><Button variant="primary" className="btn borderWhiteBtn">View Details</Button></a>
          </div>
        </div>



      </div>

    </>
  )
}

export default NotifyToast