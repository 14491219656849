



function AboutSec() {
  return (

    <section className="aboutSec" id="about-us">
      <div className="container">
        <div className="sectionTitle">
          <h6>About us</h6>
          <h1>Ben & Gaws one stop cloud <br /> based technology platform</h1>
        </div>
        <p>Ben & Gaws is a one stop cloud based technology platform enabling customers to get an end to end visibility over their
          manufacturing projects helping them to place orders easily with better cost and faster delivery and multi-layer quality
          inspection. Our vetted vendor network has delivered projects worldwide with exceptional delivery timelines and impeccable quality
          standards with cost benefits. We follow the latest standards for designing, product development, and manufacturing offers
          according to the end-user’s requirement, location, and nature of the application.</p>
      </div>
    </section>



  )
}

export default AboutSec